import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import { breakpointLargeAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import QueryWrapper from '@componentUtils/QueryWrapper'
import SearchResultsTypes from '@types/SearchResults.types'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import TypeaheadItemList from './TypeaheadItemList'

export const MIN_SEARCH_LENGTH = 2

export const StyledSearchTypeaheadDropdown = styled.div`
  background: ${props => props.theme.color.white};
  border-radius: 6px;
  box-shadow: 2px -1px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  user-select: none;
  z-index: ${props => props.theme.zIndex.typeahead};
`

export const StyledScrollContainer = styled.div`
  height: 317px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 8px;

  ${breakpointLargeAndBelow(
    css`
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    `
  )}

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.gray};
    height: 24px;
  }
`

export const getItemsLength = (searchResults, type) => {
  return searchResults[type]?.edges?.length || 0
}

const getSelectedItem = (searchResults, type, selectedIndex) => {
  if (selectedIndex === null) return null
  if (getItemsLength(searchResults, type) === 0) return null
  return searchResults[type]?.edges[selectedIndex]?.node
}

// Checks if the received response is valid
// Eg: Do we receive empty arrays or responses?
export const isValidNode = node => {
  const nodeToCheck = node.newSearch

  if (!node || !nodeToCheck) return false

  return Object.values(nodeToCheck).some(result => result)
}

const isEdgesPopulated = items => {
  return items?.edges?.length > 0
}

export const hasResults = results => {
  return Object.values(results).some(isEdgesPopulated)
}

// Automatically sets tabs based on response from the query
// Ex: If teacher doesn't receive a response but school does, it will select the school tab
const autoSetTabs = (searchResults, config, setItemType) => {
  const firstTabWithResults = config.find(c => {
    if (!searchResults[c.type]) return null
    return searchResults[c.type].edges.length
  })
  setItemType(firstTabWithResults?.type || config[0].type)
}

const setNewSelectedItem = (setSelectedItem, selectedItem, searchResults, type, selectedIndex) => {
  const newSelectedItem = getSelectedItem(searchResults, type, selectedIndex)

  const isNewItem =
    newSelectedItem && selectedItem && newSelectedItem.legacyId !== selectedItem.legacyId

  if (!selectedItem || isNewItem) {
    setSelectedItem(newSelectedItem)
  }
}

const selectOnlyItem = config => {
  const {
    setSelectedItem,
    searchResults,
    itemType,
    setSelectedIndex,
    selectedIndex,
    itemsLength
  } = config

  if (itemsLength !== 1 || itemType === SEARCH_CATEGORY.TEACHERS) return

  setSelectedIndex(0)
  const firstItem = getSelectedItem(searchResults, itemType, selectedIndex)
  setSelectedItem(firstItem)
}

export function SearchTypeaheadDropdown({
  results,
  queryValue,
  setSelectedIndex,
  selectedIndex,
  setItemsLength,
  selectedItem,
  setSelectedItem,
  itemType,
  setItemType,
  searchResults,
  setSearchResults,
  isOpen,
  config,
  searchQueryConfig,
  itemsLength,
  hideLink
}) {
  const isOpenAndHasResults = isOpen && hasResults(results)

  const typeaheadCategory = searchQueryConfig?.category

  useEffect(() => {
    if (searchQueryConfig) {
      const { setInputIconCategory, category } = searchQueryConfig

      setInputIconCategory(isOpenAndHasResults ? null : category)
    }
  }, [typeaheadCategory])

  const isNewSearch = !!searchQueryConfig

  useEffect(() => {
    if (!searchResults || JSON.stringify(searchResults) !== JSON.stringify(results)) {
      setSearchResults(results)
      // Automatically set the selected tab to be the first non-empty response
      autoSetTabs(results, config, setItemType)
    }
  }, [searchResults, results, setSearchResults, config, setItemType, autoSetTabs, itemsLength])

  // Sets the currently selected typeahead item
  useEffect(() => {
    setNewSelectedItem(setSelectedItem, selectedItem, results, itemType, selectedIndex)
  }, [setNewSelectedItem, setSelectedItem, selectedItem, results, itemType, selectedIndex])

  // Get the length of the currently selected response type
  useEffect(() => {
    setItemsLength(getItemsLength(results, itemType))
  }, [setItemsLength, getItemsLength, results, itemType])

  // auto selects the only item, if it is the only item
  useEffect(() => {
    selectOnlyItem({
      setSelectedItem,
      searchResults,
      itemType,
      setSelectedIndex,
      selectedIndex,
      itemsLength
    })
  }, [itemsLength])

  if (!isOpenAndHasResults) return null

  return (
    <StyledSearchTypeaheadDropdown
      // Prevent blurring from happening
      onMouseDown={e => {
        e.preventDefault()
      }}
      isNewSearch={!!isNewSearch}
    >
      <StyledScrollContainer>
        {config.map((item, index) => {
          const list = (
            <TypeaheadItemList
              selectedIndex={selectedIndex}
              itemType={item.type}
              queryValue={queryValue}
              results={results}
              isNewSearch={isNewSearch}
              hideLink={hideLink}
            />
          )
          return <div key={index}>{list}</div>
        })}
      </StyledScrollContainer>
    </StyledSearchTypeaheadDropdown>
  )
}

export default function SearchTypeahead(props) {
  const { query, queryValue, searchQueryConfig, count } = props

  let queryConfig = searchQueryConfig?.isNewSearch
    ? { query: { text: queryValue } }
    : { query: queryValue }

  if (!searchQueryConfig?.isSchoolSearch && searchQueryConfig?.isNewSearch) {
    queryConfig = {
      query: { ...queryConfig.query, schoolID: searchQueryConfig?.schoolID },
      count: count
    }
  }

  return (
    <QueryWrapper
      query={query}
      variables={queryConfig}
      component={node => {
        if (!isValidNode(node) || !queryValue || queryValue.length < MIN_SEARCH_LENGTH) {
          return null
        }
        return (
          <SearchTypeaheadDropdown
            results={node.newSearch}
            isNewSearch={!!searchQueryConfig}
            {...props}
          />
        )
      }}
    />
  )
}

SearchTypeahead.propTypes = {
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  queryValue: PropTypes.string,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setItemsLength: PropTypes.func,
  setSelectedItem: PropTypes.func,
  setItemType: PropTypes.func,
  results: SearchResultsTypes,
  setSearchResults: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
      label: PropTypes.string
    })
  ),
  itemsLength: PropTypes.number,
  count: PropTypes.number,
  hideLink: PropTypes.bool
}

SearchTypeaheadDropdown.propTypes = {
  ...SearchTypeahead.propTypes
}
