/**
 * @flow
 * @relayHash 4a49d8375923bf16d7d467bc286932eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StickyHeader_school$ref = any;
export type AddRatingSchoolPageQueryVariables = {|
  id: string
|};
export type AddRatingSchoolPageQueryResponse = {|
  +school: ?{|
    +name?: ?string,
    +state?: ?string,
    +city?: ?string,
    +id?: ?string,
    +legacyId?: ?number,
    +$fragmentRefs: StickyHeader_school$ref,
  |}
|};
export type AddRatingSchoolPageQuery = {|
  variables: AddRatingSchoolPageQueryVariables,
  response: AddRatingSchoolPageQueryResponse,
|};
*/


/*
query AddRatingSchoolPageQuery(
  $id: ID!
) {
  school: node(id: $id) {
    __typename
    ... on School {
      name
      state
      city
      id
      legacyId
      ...StickyHeader_school
    }
    id
  }
}

fragment StickyHeader_school on School {
  ...HeaderDescription_school
  ...HeaderRateButton_school
}

fragment HeaderDescription_school on School {
  name
  city
  state
  legacyId
}

fragment HeaderRateButton_school on School {
  ...RateSchoolLink_school
  ...CompareSchoolLink_school
}

fragment RateSchoolLink_school on School {
  legacyId
}

fragment CompareSchoolLink_school on School {
  legacyId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddRatingSchoolPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "StickyHeader_school",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddRatingSchoolPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AddRatingSchoolPageQuery",
    "id": null,
    "text": "query AddRatingSchoolPageQuery(\n  $id: ID!\n) {\n  school: node(id: $id) {\n    __typename\n    ... on School {\n      name\n      state\n      city\n      id\n      legacyId\n      ...StickyHeader_school\n    }\n    id\n  }\n}\n\nfragment StickyHeader_school on School {\n  ...HeaderDescription_school\n  ...HeaderRateButton_school\n}\n\nfragment HeaderDescription_school on School {\n  name\n  city\n  state\n  legacyId\n}\n\nfragment HeaderRateButton_school on School {\n  ...RateSchoolLink_school\n  ...CompareSchoolLink_school\n}\n\nfragment RateSchoolLink_school on School {\n  legacyId\n}\n\nfragment CompareSchoolLink_school on School {\n  legacyId\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f65fdf472584f5a392d236e466ec8d9';
module.exports = node;
