import React from 'react'
import styled, { css } from 'styled-components'
import UserTypes from '@types/User.types'
import { createFragmentContainer, graphql } from 'react-relay'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import { breakpointSmediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import TextField from '@StyledComponents/TextField'
import Form from '../components/Form/Form'
import FormCheckboxSwitch, { FormSwitchWrapper } from '../components/Form/FormCheckboxSwitch'
import { ProfileFormContainer } from './StudentProfileFields'

const StyledTextField = styled(TextField)`
  margin-bottom: 22px;
`
const StyledForm = styled(Form)`
  ${FormSwitchWrapper} {
    ${breakpointSmediumAndBelow(css`
      display: none;
    `)}
  }
`

const mutation = graphql`
  mutation TeacherProfileFieldsComponentMutation($enable: Boolean!) {
    teacherEmailAlert(enable: $enable) {
      teacher {
        emailAlerts
      }
      validation {
        ...FormController_validation
      }
    }
  }
`

export const TeacherProfileFields = ({ user }) => {
  if (!user) return null

  const { emailAlerts } = user.teacher

  const sliderText = `${
    emailAlerts ? 'I want' : "I don't want"
  } to receive weekly alerts about new ratings`

  return (
    <React.Fragment>
      <ProfileFormContainer>
        <StyledForm
          mutation={mutation}
          getVariables={() => ({
            // if a checkbox is not checked, it will not be present in FormData
            // passing in emailAlerts manually here to mitigate this
            enable: !emailAlerts
          })}
        >
          <StyledTextField
            name="Firstname"
            label="First Name"
            placeholder={user.firstName}
            labelStyle={LABEL_STYLE.LEFT}
            disabled
          />
          <StyledTextField
            name="Lastname"
            label="Last Name"
            placeholder={user.lastName}
            labelStyle={LABEL_STYLE.LEFT}
            disabled
          />
          <StyledTextField
            name="Schoolname"
            label="School Name"
            placeholder={user.school.name}
            labelStyle={LABEL_STYLE.LEFT}
            disabled
          />
          <FormCheckboxSwitch
            name="RatingAlert"
            label="Email Notifications"
            checked={emailAlerts}
            text={sliderText}
          />
        </StyledForm>
      </ProfileFormContainer>
    </React.Fragment>
  )
}

TeacherProfileFields.propTypes = {
  user: UserTypes
}

export default createFragmentContainer(TeacherProfileFields, {
  user: graphql`
    fragment TeacherProfileFields_user on User {
      firstName
      lastName
      school {
        name
      }
      legacyId
      teacher {
        emailAlerts
        legacyId
      }
    }
  `
})
