/**
 * @flow
 * @relayHash 5c9f8e728d14efaf19abd07e8ab9cbe5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
type StudentProfileFields_user$ref = any;
export type UserInputObject = {|
  major?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  graduationYear?: ?number,
  schoolId?: ?string,
  departments?: ?$ReadOnlyArray<?DeptInputObject>,
  password?: ?string,
  email: string,
  currentStanding?: ?string,
|};
export type DeptInputObject = {|
  id?: ?string,
  name?: ?string,
|};
export type StudentProfileFieldsMutationVariables = {|
  user: UserInputObject
|};
export type StudentProfileFieldsMutationResponse = {|
  +userUpdate: ?{|
    +user: ?{|
      +$fragmentRefs: StudentProfileFields_user$ref
    |},
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |},
  |}
|};
export type StudentProfileFieldsMutation = {|
  variables: StudentProfileFieldsMutationVariables,
  response: StudentProfileFieldsMutationResponse,
|};
*/


/*
mutation StudentProfileFieldsMutation(
  $user: UserInputObject!
) {
  userUpdate(user: $user) {
    user {
      ...StudentProfileFields_user
      id
    }
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment StudentProfileFields_user on User {
  id
  firstName
  lastName
  graduationYear
  school {
    id
    name
    departments {
      id
      name
    }
  }
  major
  legacyId
  email
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user",
    "type": "UserInputObject!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StudentProfileFieldsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "StudentProfileFields_user",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StudentProfileFieldsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "graduationYear",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "school",
                "storageKey": null,
                "args": null,
                "concreteType": "School",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "departments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Department",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "major",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "legacyId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "StudentProfileFieldsMutation",
    "id": null,
    "text": "mutation StudentProfileFieldsMutation(\n  $user: UserInputObject!\n) {\n  userUpdate(user: $user) {\n    user {\n      ...StudentProfileFields_user\n      id\n    }\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment StudentProfileFields_user on User {\n  id\n  firstName\n  lastName\n  graduationYear\n  school {\n    id\n    name\n    departments {\n      id\n      name\n    }\n  }\n  major\n  legacyId\n  email\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5270d7822a2fe855c6b1327431938880';
module.exports = node;
