import React from 'react'
import { graphql } from 'react-relay'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import { withRouter, Redirect } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import RateSchool from './RateSchool'
import { getVariables } from './RateSchool.utils'

export const EditRatingSchoolPageQuery = graphql`
  query EditRatingSchoolPageQuery($id: ID!, $ratingId: ID) {
    school: node(id: $id) {
      ... on School {
        name
        state
        city
        id
        legacyId
        ...StickyHeader_school
      }
    }
    rating: node(id: $ratingId) {
      ... on SchoolRating {
        clubsRating
        comment
        facilitiesRating
        foodRating
        happinessRating
        id
        internetRating
        locationRating
        opportunitiesRating
        reputationRating
        safetyRating
        socialRating
      }
    }
  }
`

export const EditRatingSchoolPage = ({ location }) => {
  const variables = getVariables(location)

  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return (
    <QueryWrapper query={EditRatingSchoolPageQuery} component={RateSchool} variables={variables} />
  )
}

EditRatingSchoolPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterEditRatingSchoolPage = withRouter(EditRatingSchoolPage)
withRouterEditRatingSchoolPage.serverQueries = [
  {
    query: EditRatingSchoolPageQuery,
    getVariables
  }
]

export default withRouterEditRatingSchoolPage
