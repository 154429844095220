import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'

import { TextFieldStyles } from '@StyledComponents/TextField'
import FormContext from '@contexts/FormContext'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import InputWrapper from '@components/StyledComponents/InputWrapper'
import Select from '@components/StyledComponents/Select'

import FieldWrapper from './FieldWrapper'

export const getFilterStyles = (theme, disabled, fullWidth) => {
  return {
    control: {
      borderColor: theme.color.gray4,
      fontWeight: theme.fontWeight.normal,
      background: disabled ? 'none' : 'white',
      width: fullWidth ? '100%' : '100px',
      '&:hover, &:focus-within': {
        borderColor: fullWidth ? theme.color.inputSelect : theme.color.gray5,
        cursor: 'pointer'
      }
    },
    option: {
      textAlign: 'left'
    },
    placeholder: {
      color: theme.color.gray8
    }
  }
}

const getFilterTheme = theme => {
  return {
    borderRadius: '3px',
    fontWeight: theme.fontWeight.normal,
    fontSize: theme.fontSize.large
  }
}

export const StyledSelect = styled(Select)`
  max-width: 360px;
`

export const DisabledInput = styled.input`
  max-width: ${({ theme }) => theme.mainColumnWidth.form};
  ${TextFieldStyles}
`

const FormSelect = ({
  disabled,
  name,
  className,
  label,
  options,
  placeholder = '',
  fullWidth = false,
  labelStyle
}) => {
  const theme = useContext(ThemeContext)
  const { locked, model, onChange } = useContext(FormContext)
  const selected = options.find(o => `${o.value}` === `${model[name]?.value || model[name]}`)

  return (
    <FieldWrapper fieldName={name} className={className}>
      <InputWrapper
        hasValue={!!model?.[name]}
        name={name}
        label={label}
        labelStyle={labelStyle || LABEL_STYLE.LEFT}
        className={className}
      >
        {/* Our current forms call for a disabled select to only show it's value, instead of looking like a disabled select box, in the future we may need to make this a prop or something */}
        {(locked || disabled) && <DisabledInput disabled type="text" value={selected?.label} />}
        {!locked && !disabled && (
          <StyledSelect
            value={selected || null}
            name={name}
            inputId={name}
            onChange={value => onChange({ target: { name, value } })}
            options={options}
            placeholder={placeholder}
            additionalStyles={getFilterStyles(theme, locked || disabled, fullWidth)}
            additionalTheming={getFilterTheme(theme)}
            isSearchable
          />
        )}
      </InputWrapper>
    </FieldWrapper>
  )
}

FormSelect.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  labelStyle: PropTypes.string
}

export default FormSelect
