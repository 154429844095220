/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HeaderDescription_school$ref = any;
type HeaderRateButton_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StickyHeader_school$ref: FragmentReference;
declare export opaque type StickyHeader_school$fragmentType: StickyHeader_school$ref;
export type StickyHeader_school = {|
  +$fragmentRefs: HeaderDescription_school$ref & HeaderRateButton_school$ref,
  +$refType: StickyHeader_school$ref,
|};
export type StickyHeader_school$data = StickyHeader_school;
export type StickyHeader_school$key = {
  +$data?: StickyHeader_school$data,
  +$fragmentRefs: StickyHeader_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "StickyHeader_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "HeaderDescription_school",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "HeaderRateButton_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e7476ffa5a2cc0dcae3e90956810e13a';
module.exports = node;
