import React from 'react'
import ROUTE from '@enums/ROUTE'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import SchoolTypes from '@types/School.types'
import RatingTypes from '@types/Rating.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import PageWrapper from '@components/PageWrapper'
import Form from '@components/Form/Form'
import FormCard from '@components/Form/FormCard'
import RatingSlider from '@components/Form/ScaleSlider/RatingSlider'
import {
  getPageContentMaxWidth,
  breakpointLargePlusAndBelow,
  breakpointTinyAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import AdRail from '@components/Ads/AdRail'
import StickyHeader from '@components/StickyHeader/StickyHeader'
import SubmitArea from '@pages/AddRating/Sections/SubmitArea'
import WriteReview from '@pages/AddRating/Sections/WriteReview'
import SEOMetaInfo from '@components/SEOMetaInfo'
import generateCompId from '@utils/generateCompId'
import {
  rateSchoolMutation,
  editRateSchoolMutation,
  generateExistingData
} from './RateSchool.utils'
import rateSchoolModel from './RateSchool.model'

export const StyledAddRatingSchoolPage = styled(PageWrapper)`
  ${breakpointLargePlusAndBelow(css`
    padding: 0 24px;
  `)}
  ${breakpointTinyAndBelow(css`
    padding: 0;
  `)}
`

export const StyledAddRatingSchoolForm = styled(Form)`
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

export function RateSchool({ school, rating, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }
  const { sliders, writeReview } = rateSchoolModel

  const history = useHistory()
  const generateGetVariables = model => {
    const getVariablesData = {
      schoolID: school.id,
      location: model.location,
      opportunities: model.opportunities,
      social: model.social,
      safety: model.safety,
      comment: parseAndSanitize(model.comment),
      internet: model.internet,
      facilities: model.facilities,
      food: model.food,
      clubs: model.clubs,
      happiness: model.happiness,
      reputation: model.reputation,
      recaptcha: model.recaptcha,
      compID: generateCompId()
    }
    if (rating?.id) getVariablesData.ID = rating.id
    return getVariablesData
  }

  const isEditRating = !!rating

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${isEditRating ? 'Edit your' : 'Add a'} rating for ${
          school.name
        } | Rate My Professors`}
        description={`${isEditRating ? 'Edit your' : 'Add a'} rating for ${school.name}`}
      />
      <StickyHeader school={school} teacher={null} />
      <StyledAddRatingSchoolPage>
        <AdLeaderboard code="ad_lb_top_ratings" />
        <StyledAddRatingSchoolForm
          getVariables={model => generateGetVariables(model)}
          existingData={rating && generateExistingData(rating)}
          mutation={rating ? editRateSchoolMutation : rateSchoolMutation}
          onSuccess={() => {
            history.push({
              pathname: ROUTE.ADD_CAMPUS_RATING_SUCCESS.replace(':sid', school.legacyId),
              state: { school }
            })
          }}
          onError
        >
          {sliders.map(({ name, label, isRequired, type }) => {
            return (
              <FormCard fieldName={name} label={label} isRequired={isRequired} key={name}>
                <RatingSlider type={type} label={label} name={name} />
              </FormCard>
            )
          })}
          <FormCard
            fieldName={writeReview.name}
            label={writeReview.label}
            subTitle={writeReview.subTitle}
          >
            <WriteReview
              name={writeReview.name}
              guidelines={writeReview.guidelines}
              placeholder={writeReview.placeholder}
            />
          </FormCard>
          <FormCard>
            <SubmitArea isEditRating={isEditRating} />
          </FormCard>
        </StyledAddRatingSchoolForm>
        <AdRail />
      </StyledAddRatingSchoolPage>
    </React.Fragment>
  )
}

RateSchool.propTypes = {
  school: SchoolTypes,
  loading: PropTypes.bool,
  rating: RatingTypes
}

export default withRouter(RateSchool)
