/**
 * @flow
 * @relayHash 55b8a2b5248cf9c03d27684aff5387cc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserInputObject = {|
  major?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  graduationYear?: ?number,
  schoolId?: ?string,
  departments?: ?$ReadOnlyArray<?DeptInputObject>,
  password?: ?string,
  email: string,
  currentStanding?: ?string,
|};
export type DeptInputObject = {|
  id?: ?string,
  name?: ?string,
|};
export type getAndSetUserSchoolMutationVariables = {|
  user: UserInputObject
|};
export type getAndSetUserSchoolMutationResponse = {|
  +userUpdate: ?{|
    +user: ?{|
      +school: ?{|
        +id: ?string
      |}
    |}
  |}
|};
export type getAndSetUserSchoolMutation = {|
  variables: getAndSetUserSchoolMutationVariables,
  response: getAndSetUserSchoolMutationResponse,
|};
*/


/*
mutation getAndSetUserSchoolMutation(
  $user: UserInputObject!
) {
  userUpdate(user: $user) {
    user {
      school {
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user",
    "type": "UserInputObject!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "school",
  "storageKey": null,
  "args": null,
  "concreteType": "School",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAndSetUserSchoolMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getAndSetUserSchoolMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "getAndSetUserSchoolMutation",
    "id": null,
    "text": "mutation getAndSetUserSchoolMutation(\n  $user: UserInputObject!\n) {\n  userUpdate(user: $user) {\n    user {\n      school {\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de763338b43d429584d86dc0257f2b93';
module.exports = node;
