const AD_SCRIPTS = {
  BROWSI: {
    id: 'browsi-tag',
    src: 'https://cdn.browsiprod.com/bootstrap/bootstrap.js',
    'data-pubKey': 'alticermp',
    'data-siteKey': 'rmp1'
  }
}

export default AD_SCRIPTS
