/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeacherProfileFields_user$ref: FragmentReference;
declare export opaque type TeacherProfileFields_user$fragmentType: TeacherProfileFields_user$ref;
export type TeacherProfileFields_user = {|
  +firstName: ?string,
  +lastName: ?string,
  +school: ?{|
    +name: ?string
  |},
  +legacyId: ?number,
  +teacher: ?{|
    +emailAlerts: ?boolean,
    +legacyId: ?number,
  |},
  +$refType: TeacherProfileFields_user$ref,
|};
export type TeacherProfileFields_user$data = TeacherProfileFields_user;
export type TeacherProfileFields_user$key = {
  +$data?: TeacherProfileFields_user$data,
  +$fragmentRefs: TeacherProfileFields_user$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TeacherProfileFields_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teacher",
      "storageKey": null,
      "args": null,
      "concreteType": "Teacher",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "emailAlerts",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '711aa6b90f2f429d8389c8d7e0a3b49e';
module.exports = node;
