/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Redirect, withRouter, useHistory, matchPath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import { PENDO_TRACKING_EVENT } from '@enums/TRACKING_EVENT'
import LOCK_STATUS from '@enums/LOCK_STATUS'
import ROUTE from '@enums/ROUTE'
import {
  getPageContentMaxWidth,
  breakpointLargeAndBelow,
  breakpointMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import TaboolaLoader from '@lib/TaboolaLoader'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PageWrapper from '@components/PageWrapper'
import TeacherInfo from '@components/TeacherInfo/TeacherInfo'
import TeacherRatingTabs from '@components/TeacherRatingTabs'
import TaboolaAd, { INFINITE_TABOOLA_ANCHOR } from '@components/Ads/TaboolaAd'
import SimilarProfessors from '@components/SimilarProfessors'
import AdRail from '@components/Ads/AdRail'
import RedirectWithStatus from '@components/RedirectWithStatus'
import toast, { TOAST_EVENTS } from '@utils/toast'
import SoftlockedTeacherToast from '@components/Toast/SoftlockedTeacherToast'
import HardlockedTeacherToast from '@components/Toast/HardlockedTeacherToast'
import FlagRatingSuccessToast from '@components/Toast/FlagRatingSuccessToast'
import useUserInfo from '@hooks/useUserInfo'
import RatingDistributionWrapper from '@components/RatingDistribution/RatingDistributionWrapper'
import BrowsiVideoPlayer from '@components/Ads/BrowsiVideoPlayer'
import Pendo from '@lib/Pendo'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdController from '@components/Ads/AdController'
import isNumericString from '@utils/isNumericString'
import useDynamicScript from '@hooks/useDynamicScript'
import AD_SCRIPTS from '@enums/AD_SCRIPTS'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import { getCountryName } from './SchoolRatingsPage/SchoolRatings.utils'

const AD_TOP_PROF_PAGE = 120

export const RatingColumn = styled.div`
  display: flex;
  flex: 1 0 60%;
  flex-flow: column nowrap;
  padding-right: ${props => props.theme.padding.teacherInfo};
`

export const TeacherBlock = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 6px;
  padding-left: ${props => props.theme.padding.teacherInfo};

  ${breakpointLargeAndBelow(css`
    flex-flow: column;
    margin-bottom: 16px;
  `)}

  ${breakpointMediumAndBelow(
    css`
      margin-bottom: 24px;
    `
  )}
  
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const query = graphql`
  query TeacherRatingsPageQuery($id: ID!) {
    node(id: $id) {
      ... on Teacher {
        id
        legacyId
        firstName
        lastName
        department
        school {
          legacyId
          name
          city
          state
          country
        }
        lockStatus
        ...StickyHeader_teacher
        ...RatingDistributionWrapper_teacher
        ...TeacherInfo_teacher
        ...SimilarProfessors_teacher
        ...TeacherRatingTabs_teacher
      }
    }
  }
`
export function TeacherRatings({ node }) {
  const userInfo = useUserInfo()
  const isLoggedIn = !!userInfo?.Email
  const history = useHistory()
  const historyToastEvent = history?.location?.state?.toast

  useDynamicScript(AD_SCRIPTS.BROWSI)

  const [adTargeting, setAdTargeting] = useState()

  useEffect(() => {
    if (!node) return

    const url = window.location.href.split('?')[0]
    Pendo.track({
      event: PENDO_TRACKING_EVENT.TRACK_PROFESSOR_SCHOOL,
      options: {
        URL: url,
        PROFESSOR_NAME: `${node.firstName} ${node.lastName}`,
        PROFESSOR_ID: node.legacyId,
        SCHOOL_NAME: node.school.name,
        SCHOOL_ID: node.school.legacyId,
        SCHOOL_CITY: node.school.city,
        SCHOOL_STATE: node.school.state
      }
    })

    if (!adTargeting) {
      setAdTargeting({
        school_name: node.school.name,
        school_city: node.school.city,
        school_state: node.school.state,
        school_country: getCountryName(node.school.country)
      })
    }

    const { lockStatus } = node

    if (lockStatus === LOCK_STATUS.HARD) {
      toast(
        <HardlockedTeacherToast teacherName={node.lastName} />,
        TOAST_EVENTS.HARD_LOCKED_TEACHER
      )
    }

    if (lockStatus === LOCK_STATUS.SOFT && !isLoggedIn) {
      toast(
        <SoftlockedTeacherToast teacherName={node.lastName} />,
        TOAST_EVENTS.SOFT_LOCKED_TEACHER
      )
    }

    if (historyToastEvent === TOAST_EVENTS.FLAG_RATING_SUCCESS) {
      toast(<FlagRatingSuccessToast />, TOAST_EVENTS.FLAG_RATING_SUCCESS)
      history.replace({
        ...history.location,
        state: null
      })
    }
  }, [node])

  if (node === undefined) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  if (node === null || !node.legacyId) {
    return <RedirectWithStatus to={ROUTE.TEACHER_NOT_FOUND} status={301} />
  }

  const getMetaDescription = teacher => {
    if (!teacher.department) {
      return `${teacher.firstName} ${teacher.lastName}  is a professor at ${teacher.school.name} - see what their students are saying about them or leave a rating yourself.`
    }

    return `${teacher.firstName} ${teacher.lastName}  is a professor in the ${teacher.department} department at ${teacher.school.name} - see what their students are saying about them or leave a rating yourself.`
  }

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${node.firstName} ${node.lastName} at ${node.school.name} | Rate My Professors`}
        description={getMetaDescription(node)}
      />
      <PageWrapper>
        <AdLeaderboard code="ad_lb_top_ratings" />
        <TeacherBlock>
          <TeacherInfo teacher={node} />
          <RatingColumn>
            <RatingDistributionWrapper teacher={node} />
            <SimilarProfessors teacher={node} />
            <AdController>
              <BrowsiVideoPlayer />
            </AdController>
          </RatingColumn>
        </TeacherBlock>
        <AdRail adTop={AD_TOP_PROF_PAGE} adTargetingSchools={adTargeting} />
        <TeacherRatingTabs teacher={node} />
        <AdController>
          <TaboolaAd id={INFINITE_TABOOLA_ANCHOR} type={TaboolaLoader.UNIT_TYPE.BELOW_ARTICLE} />
        </AdController>
      </PageWrapper>
    </React.Fragment>
  )
}

TeacherRatings.propTypes = {
  node: TeacherTypes
}

const getVariables = location => {
  const match = matchPath(location.pathname, { path: ROUTE.SHOW_RATINGS })

  if (!match || !match.params || !match.params.tid || !isNumericString(match.params.tid))
    return { id: '' }
  return { id: btoa(`Teacher-${match.params.tid}`) }
}

export const TeacherRatingsPage = ({ location }) => {
  const variables = getVariables(location)

  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return <QueryWrapper query={query} component={TeacherRatings} variables={variables} />
}

TeacherRatingsPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterTeacherRatingsPage = withRouter(TeacherRatingsPage)
withRouterTeacherRatingsPage.serverQueries = [
  {
    query,
    getVariables
  }
]

export default withRouterTeacherRatingsPage
