import React from 'react'
import styled, { css } from 'styled-components'
import RateTeacherLink from '@components/RateTeacherLink'
import RateSchoolLink from '@components/RateSchoolLink'
import CompareSchoolLink from '@components/CompareSchoolLink'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import { createFragmentContainer, graphql } from 'react-relay'
import {
  breakpointLargeAndBelow,
  breakpointSmallPlusAndBelow,
  breakpointSmediumAndBelow,
  breakpointXLargeAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import useCompareSchools from '@utils/useCompareSchools'

const RateButtonAttributes = css`
  ${ButtonAttributes}
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
  max-width: 284px;
  text-decoration: none;

  ${breakpointLargeAndBelow(css``)}

  ${breakpointSmediumAndBelow(css`
    width: 100%;
    margin-top: 0px;
  `)}

  ${breakpointSmallPlusAndBelow(css`
    max-width: 100%;
  `)}

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

export const StyledRateTeacherButton = styled(RateTeacherLink)`
  ${RateButtonAttributes}
`
export const StyledRateSchoolButton = styled(RateSchoolLink)`
  ${RateButtonAttributes}
`

export const StyledCompareSchoolButton = styled(CompareSchoolLink)`
  margin-left: 8px;
  ${RateButtonAttributes}

  ${breakpointSmallPlusAndBelow(css`
    margin-left: 0px;
    margin-top: 8px;
  `)}
`

const SchoolActionButtons = styled.div`
  display: flex;

  ${breakpointXLargeAndBelow(css`
    flex-direction: row;
  `)}

  ${breakpointSmallPlusAndBelow(css`
    flex-direction: column;
  `)}
`

export function HeaderRateButton({ teacher, school }) {
  const isCompareSchoolOn = useCompareSchools()
  const trackingLabel = () => {
    if (teacher) {
      return teacher.numRatings === 0
        ? TRACKING_EVENT.PROF_RATE_BUTTON_TOP
        : TRACKING_EVENT.PROF_RATE_BUTTON
    }
    return TRACKING_EVENT.SCHOOL_RATE_BUTTON_TOP
  }

  return teacher ? (
    <StyledRateTeacherButton teacher={teacher} trackingLabel={trackingLabel()} rightArrow blue>
      Rate
    </StyledRateTeacherButton>
  ) : (
    <React.Fragment>
      <SchoolActionButtons>
        <StyledRateSchoolButton
          id="rate-school-btn"
          school={school}
          trackingLabel={trackingLabel()}
          blue
        >
          Rate this school
        </StyledRateSchoolButton>
        {isCompareSchoolOn && (
          <StyledCompareSchoolButton
            id="compare-school-btn"
            school={school}
            trackingLabel={trackingLabel()}
            white
            secondary
          >
            Compare this school
          </StyledCompareSchoolButton>
        )}
      </SchoolActionButtons>
    </React.Fragment>
  )
}

export default createFragmentContainer(HeaderRateButton, {
  teacher: graphql`
    fragment HeaderRateButton_teacher on Teacher {
      ...RateTeacherLink_teacher
    }
  `,
  school: graphql`
    fragment HeaderRateButton_school on School {
      ...RateSchoolLink_school
      ...CompareSchoolLink_school
    }
  `
})

HeaderRateButton.propTypes = {
  teacher: TeacherTypes,
  school: SchoolTypes
}
