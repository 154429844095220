import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import RedirectWithStatus from '@components/RedirectWithStatus'
import SchoolTypes from '@types/School.types'
import TRACKING_EVENT, { PENDO_TRACKING_EVENT } from '@enums/TRACKING_EVENT'
import ROUTE from '@enums/ROUTE'
import {
  breakpointSmediumAndBelow,
  getPageContentMaxWidth
} from '@components/StyledComponents/theme/helpers/breakpoints'
import PageWrapper from '@components/PageWrapper'
import TaboolaLoader from '@lib/TaboolaLoader'
import TaboolaAd, { INFINITE_TABOOLA_ANCHOR } from '@components/Ads/TaboolaAd'
import StickyHeader from '@components/StickyHeader/StickyHeader'
import SchoolSummary from '@components/SchoolSummary/SchoolSummary'
import AdRail from '@components/Ads/AdRail'
import NoRatingsArea from '@components/StyledComponents/NoRatingsArea'
import BrowsiVideoPlayer from '@components/Ads/BrowsiVideoPlayer'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import Pendo from '@lib/Pendo'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdController from '@components/Ads/AdController'
import useDynamicScript from '@hooks/useDynamicScript'
import AD_SCRIPTS from '@enums/AD_SCRIPTS'
import OverallRating from './OverallRating'
import SchoolRatingsContainer from './SchoolRatingsContainer'
import { getCountryName } from './SchoolRatings.utils'

const AD_TOP_SCHOOL_PAGE = 286

const MainInfoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  ${breakpointSmediumAndBelow(css`
    flex-direction: column;
  `)}
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const VideoPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

export function SchoolRatings({ school }) {
  useDynamicScript(AD_SCRIPTS.BROWSI)

  const [adTargeting, setAdTargeting] = useState()
  useEffect(() => {
    if (!school) return
    setAdTargeting({
      school_name: school.name,
      school_city: school.city,
      school_state: school.state,
      school_country: getCountryName(school.country)
    })

    const url = window.location.href.split('?')[0]
    Pendo.track({
      event: PENDO_TRACKING_EVENT.TRACK_SCHOOL_INFO,
      options: {
        URL: url,
        SCHOOL_NAME: school.name,
        SCHOOL_ID: school.legacyId,
        SCHOOL_CITY: school.city,
        SCHOOL_STATE: school.state
      }
    })
  }, [school])

  if (school === undefined) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  if (school === null || !school.legacyId) {
    return <RedirectWithStatus to={ROUTE.CAMPUS_NOT_FOUND} status={301} />
  }

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${school.name} | Rate My Professors`}
        description={`See what students are saying about ${school.name} or leave a rating yourself.`}
      />
      <StickyHeader school={school} teacher={null} large />
      <PageWrapper>
        <AdLeaderboard code="ad_lb_top_ratings" />
        {school.numRatings === 0 ? (
          <NoRatingsArea
            school={school}
            trackingLabel={TRACKING_EVENT.SCHOOL_RATE_BUTTON_BOTTOM}
            gray
          />
        ) : (
          <React.Fragment>
            <MainInfoContainer>
              <OverallRating school={school} />
              <SchoolSummary school={school} />
            </MainInfoContainer>
            <AdController>
              <VideoPlayerWrapper>
                <BrowsiVideoPlayer />
              </VideoPlayerWrapper>
            </AdController>
            <AdRail adTop={AD_TOP_SCHOOL_PAGE} adTargetingSchools={adTargeting} />
            <SchoolRatingsContainer school={school} />
          </React.Fragment>
        )}
        <AdController>
          <TaboolaAd id={INFINITE_TABOOLA_ANCHOR} type={TaboolaLoader.UNIT_TYPE.BELOW_ARTICLE} />
        </AdController>
      </PageWrapper>
    </React.Fragment>
  )
}

SchoolRatings.propTypes = {
  school: SchoolTypes
}

export default withRouter(SchoolRatings)
