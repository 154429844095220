/* eslint-disable react/no-unknown-property */
import React from 'react'
import theme from '@components/StyledComponents/theme'

const BrowsiVideoPlayer = () => {
  return (
    <div
      id="rmp-browsi-video-player"
      data-type="float"
      style={{
        postion: 'static',
        height: 'auto',
        width: 'auto',
        maxWidth: '524px',
        marginTop: '16px',
        marginBottom: '16px',
        zIndex: theme.zIndex.videoAnchor
      }}
    />
  )
}

export default BrowsiVideoPlayer
