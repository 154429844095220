import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { poppinsBoldest3x } from './theme/typesets'

const ColoredSquare = styled.div`
  background: ${({ color, theme }) => theme.color[color]};
  line-height: ${({ large }) => (large ? '40px' : '24px')};
  min-height: ${({ large }) => (large ? '72px' : '40px')};
  min-width: 44px;
  padding: ${({ large }) => `${large ? '16px' : '8px'} 0`};
  width: ${({ large }) => (large ? '72px' : '44px')};

  ${poppinsBoldest3x}
`

const GradeSquare = ({ grade, large }) => {
  const calculateColor = () => {
    if (!grade) return 'noScore'
    if (grade > 3.9) return 'awesomeScore'
    if (grade < 3.0) return 'awfulScore'
    return 'averageScore'
  }

  return (
    <ColoredSquare large={large} grade={grade} color={calculateColor()}>
      {grade || 'N/A'}
    </ColoredSquare>
  )
}

GradeSquare.propTypes = {
  grade: PropTypes.string,
  large: PropTypes.bool
}

export default GradeSquare
