import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  breakpointSmallAndBelow,
  breakpointSmediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import SocialLinks, { SOCIAL_ICON_COLORS } from '@components/SocialLinks'
import { SocialIcon } from '@components/SocialIconLink'
import ToastContainer from '@components/Toast/ToastContainer'
import UserMenu, {
  MyAccountButton,
  LoginLink,
  SignUpLink,
  UserMenuWrapper
} from '@components/UserMenu'
import MobileMenu, { MobileMenuWrapper } from '@components/MobileMenu'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;
  padding: 33px 39px;
  width: 100%;

  ${breakpointSmediumAndBelow(
    css`
      padding: 16px 32px;
    `
  )}

  ${breakpointSmallAndBelow(
    css`
      padding: 16px;
    `
  )}

  ${SocialIcon} {
    height: unset;
    width: unset;
  }

  a:first-child {
    img {
      padding-left: 0;
    }
  }
`

const StyledHomepageHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  width: 100%;
`

const noMarginBelowSmedium = css`
  ${breakpointSmediumAndBelow(
    css`
      margin: 0;
    `
  )}
`

const LoginButtonStyles = css`
  color: black;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: 8px 12px;

  ${noMarginBelowSmedium}
  &:hover {
    background-color: unset;
    text-decoration: underline;
  }
`
const SignupButtonStyles = css`
  background-color: black;
  border-color: black;
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding: 8px 24px;

  ${noMarginBelowSmedium}
  &:hover {
    background-color: ${({ theme }) => theme.color.gray10};
  }
`
const AccountButtonStyles = css`
  background-color: black;
  border: 2px solid black;
  color: white;
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding: 8px 24px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray10};
    text-decoration: none;
  }
`

const UserItems = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  z-index: ${props => props.theme.zIndex.search};

  ${breakpointSmediumAndBelow(css`
    ${MobileMenuWrapper} {
      display: block;
    }

    ${UserMenuWrapper},
    ${MyAccountButton} {
      display: none;
    }
  `)}

  ${LoginLink} {
    ${LoginButtonStyles}
  }

  ${MyAccountButton} {
    ${AccountButtonStyles}
  }

  ${SignUpLink} {
    ${SignupButtonStyles}
  }
`

export default function HomepageHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (isMobileMenuOpen) {
      disableBodyScroll()
      return
    }

    enableBodyScroll()
  }, [isMobileMenuOpen])

  return (
    <HeaderWrapper>
      <ToastContainer limit={1} />
      <StyledHomepageHeader>
        <SocialLinks iconColor={SOCIAL_ICON_COLORS.BLACK} />
        <UserItems>
          <UserMenu />
          <MobileMenu
            isLightButton={false}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </UserItems>
      </StyledHomepageHeader>
    </HeaderWrapper>
  )
}
