import { Network } from 'relay-runtime'
import * as Sentry from '@sentry/react'
import Cookies from '@utils/Cookies'
import isSSR from '@utils/isSSR'
import ROUTE from '@enums/ROUTE'
import processLogout, { LOGIN_COOKIES } from '@utils/processLogout'

export const getAuthHeader = (basicAuthToken, cookies) => {
  // Needed for SSR, since cookies may not be available via global context
  let cookieAuth = cookies && Cookies.parse(cookies)?.rmpAuth
  if (cookieAuth) {
    return `Bearer ${cookieAuth}`
  }

  // Regular cookie auth
  if (!isSSR()) {
    cookieAuth = Cookies.get('rmpAuth')
    if (cookieAuth) {
      return `Bearer ${cookieAuth}`
    }
  }

  return `Basic ${basicAuthToken}`
}

export const getRequestOptions = ({ operation, variables, basicAuthToken, cookies }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: getAuthHeader(basicAuthToken, cookies)
  }

  // Cookies will only be defined during SSR render
  if (cookies) {
    headers.Cookie = cookies
  }

  return {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      query: operation.text,
      variables
    }),
    headers
  }
}

export const fetchQuery = ({ server, basicAuthToken, cookies, setCookies, clearCookie }) => {
  return (operation, variables) => {
    const optionsConfig = {
      operation,
      variables,
      basicAuthToken,
      cookies
    }

    const options = getRequestOptions(optionsConfig)
    return fetch(`${server}/graphql`, options)
      .then(response => {
        if (response.status === 401) {
          if (isSSR() && clearCookie) {
            LOGIN_COOKIES.forEach(c => clearCookie(c))
          } else {
            processLogout(false)
          }
        }
        return response
      })
      .then(response => {
        if (setCookies && response.headers.has('set-cookie')) {
          setCookies(response.headers.get('set-cookie'))
        }
        return response.json()
      })
      .catch(error => {
        if (isSSR()) {
          Sentry.captureException(error)
          throw error
        } else {
          window.location.assign(`${ROUTE.ERROR}?type=net`)
        }
      })
  }
}

export default config => {
  return Network.create(fetchQuery(config))
}
