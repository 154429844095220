import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { createPaginationContainer, graphql } from 'react-relay'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@node_modules/@rmwc/circular-progress/circular-progress.css'
import RelayTypes from '@types/Relay.types'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import PaginationButton from '@StyledComponents/PaginationButton'
import TeacherTypes from '@types/Teacher.types'
import NoRatingsArea from '@StyledComponents/NoRatingsArea'
import BrowsiAd from '@components/Ads/BrowsiAd'
import AdController from '@components/Ads/AdController'
import Rating from './Rating/Rating'
import AdLabel from './Ads/AdLabel'

export const BROWSI_SLOT_INDEX_ONE = 1
export const BROWSI_SLOT_INDEX_TWO = 3
export const GPT_AD_INTERVAL = 3
export const GPT_AD_OFFSET = 5
export const RATINGS_LIST_ANCHOR = 'ratingsList'

const buildRatingList = teacher => {
  const result = []
  let adCount = 0
  teacher.ratings.edges.forEach((edge, i) => {
    result.push(<Rating key={edge.cursor} teacher={teacher} rating={edge.node} />)
    const browsiId = `browsi-ratings-page-${adCount}`
    const browsiReplacementId = `new-browsi-prof-page-${adCount}`

    // Putting Browsi in index 1 and 3
    if (i === BROWSI_SLOT_INDEX_ONE - 1 || i === BROWSI_SLOT_INDEX_TWO) {
      adCount += 1
      result.push(
        <AdController>
          <AdLabel />
          <BrowsiAd id={browsiReplacementId} key={browsiReplacementId} />
        </AdController>
      )
    }

    // Browsi (GAM) Ads
    if (i >= GPT_AD_OFFSET && (i - GPT_AD_OFFSET) % GPT_AD_INTERVAL === 0) {
      adCount += 1
      result.push(
        <AdController>
          <AdLabel />
          <BrowsiAd id={browsiId} key={browsiId} />
        </AdController>
      )
    }
  })
  return result
}

export const RatingsUL = styled.ul`
  list-style: none;
`
export function RatingsList({ teacher, relay, courseFilter }) {
  const [isLoading, setIsLoading] = useRelayIsLoading(relay, [relay, teacher])
  const isFirstRender = useRef(true)

  if (teacher.numRatings === 0) {
    return (
      <NoRatingsArea teacher={teacher} trackingLabel={TRACKING_EVENT.PROF_RATE_BUTTON_BOTTOM} />
    )
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    setIsLoading(true)
    relay.refetchConnection(
      20,
      error => {
        if (error) {
          console.error(error)
        }
      },
      {
        courseFilter: courseFilter || null
      }
    )
  }, [courseFilter])

  const loadMore = () => {
    relay.loadMore(
      10, // Fetch the next 10 feed items
      error => {
        if (error) {
          console.error(error)
        }
      }
    )
    setIsLoading(true)
  }

  return (
    <React.Fragment>
      <RatingsUL id={RATINGS_LIST_ANCHOR}>
        {buildRatingList(teacher).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </RatingsUL>

      <PaginationButton isLoading={isLoading} hasMore={relay.hasMore()} onClick={loadMore}>
        Load More Ratings
      </PaginationButton>
      {/* TODO: Ask design if there should be a CTA when we're out of ratings */}
    </React.Fragment>
  )
}

RatingsList.propTypes = {
  teacher: TeacherTypes,
  relay: RelayTypes,
  courseFilter: PropTypes.string
}

export default createPaginationContainer(
  RatingsList,
  {
    teacher: graphql`
      fragment RatingsList_teacher on Teacher
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 20 }
          cursor: { type: "String", defaultValue: null }
          courseFilter: { type: "String", defaultValue: null }
        ) {
        id
        legacyId
        lastName
        numRatings
        school {
          id
          legacyId
          name
          city
          state
          avgRating
          numRatings
        }
        ...Rating_teacher
        ...NoRatingsArea_teacher
        ratings(first: $count, after: $cursor, courseFilter: $courseFilter)
          @connection(key: "RatingsList_ratings") {
          edges {
            cursor
            node {
              ...Rating_rating
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.teacher && props.teacher.ratings
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.teacher.id, // Intentional node_id
        courseFilter: props.courseFilter || null
      }
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query RatingsListQuery($count: Int!, $id: ID!, $courseFilter: String, $cursor: String) {
        node(id: $id) {
          ... on Teacher {
            ...RatingsList_teacher
              @arguments(count: $count, cursor: $cursor, courseFilter: $courseFilter)
          }
        }
      }
    `
  }
)
