/**
 * @flow
 * @relayHash c08e03ef6069b0c068dc162b58a41361
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StickyHeader_school$ref = any;
export type EditRatingSchoolPageQueryVariables = {|
  id: string,
  ratingId?: ?string,
|};
export type EditRatingSchoolPageQueryResponse = {|
  +school: ?{|
    +name?: ?string,
    +state?: ?string,
    +city?: ?string,
    +id?: ?string,
    +legacyId?: ?number,
    +$fragmentRefs: StickyHeader_school$ref,
  |},
  +rating: ?{|
    +clubsRating?: ?number,
    +comment?: ?any,
    +facilitiesRating?: ?string,
    +foodRating?: ?number,
    +happinessRating?: ?number,
    +id?: ?string,
    +internetRating?: ?number,
    +locationRating?: ?number,
    +opportunitiesRating?: ?number,
    +reputationRating?: ?number,
    +safetyRating?: ?number,
    +socialRating?: ?number,
  |},
|};
export type EditRatingSchoolPageQuery = {|
  variables: EditRatingSchoolPageQueryVariables,
  response: EditRatingSchoolPageQueryResponse,
|};
*/


/*
query EditRatingSchoolPageQuery(
  $id: ID!
  $ratingId: ID
) {
  school: node(id: $id) {
    __typename
    ... on School {
      name
      state
      city
      id
      legacyId
      ...StickyHeader_school
    }
    id
  }
  rating: node(id: $ratingId) {
    __typename
    ... on SchoolRating {
      clubsRating
      comment
      facilitiesRating
      foodRating
      happinessRating
      id
      internetRating
      locationRating
      opportunitiesRating
      reputationRating
      safetyRating
      socialRating
    }
    id
  }
}

fragment StickyHeader_school on School {
  ...HeaderDescription_school
  ...HeaderRateButton_school
}

fragment HeaderDescription_school on School {
  name
  city
  state
  legacyId
}

fragment HeaderRateButton_school on School {
  ...RateSchoolLink_school
  ...CompareSchoolLink_school
}

fragment RateSchoolLink_school on School {
  legacyId
}

fragment CompareSchoolLink_school on School {
  legacyId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ratingId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ratingId"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clubsRating",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "facilitiesRating",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "foodRating",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "happinessRating",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "internetRating",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationRating",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "opportunitiesRating",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reputationRating",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "safetyRating",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "socialRating",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditRatingSchoolPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "StickyHeader_school",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "SchoolRating",
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v5/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditRatingSchoolPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "SchoolRating",
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "EditRatingSchoolPageQuery",
    "id": null,
    "text": "query EditRatingSchoolPageQuery(\n  $id: ID!\n  $ratingId: ID\n) {\n  school: node(id: $id) {\n    __typename\n    ... on School {\n      name\n      state\n      city\n      id\n      legacyId\n      ...StickyHeader_school\n    }\n    id\n  }\n  rating: node(id: $ratingId) {\n    __typename\n    ... on SchoolRating {\n      clubsRating\n      comment\n      facilitiesRating\n      foodRating\n      happinessRating\n      id\n      internetRating\n      locationRating\n      opportunitiesRating\n      reputationRating\n      safetyRating\n      socialRating\n    }\n    id\n  }\n}\n\nfragment StickyHeader_school on School {\n  ...HeaderDescription_school\n  ...HeaderRateButton_school\n}\n\nfragment HeaderDescription_school on School {\n  name\n  city\n  state\n  legacyId\n}\n\nfragment HeaderRateButton_school on School {\n  ...RateSchoolLink_school\n  ...CompareSchoolLink_school\n}\n\nfragment RateSchoolLink_school on School {\n  legacyId\n}\n\nfragment CompareSchoolLink_school on School {\n  legacyId\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0eb0077a71bb50d1792c21037919912d';
module.exports = node;
