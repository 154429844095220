import React, { useRef, useState } from 'react'
import ROUTE from '@enums/ROUTE'
import styled, { css } from 'styled-components'
import {
  breakpointLargePlusAndBelow,
  breakpointTinyAndBelow,
  getPageContentMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import { withRouter, useHistory } from 'react-router-dom'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import PageWrapper from '@components/PageWrapper'
import AdRail from '@components/Ads/AdRail'
import Form from '@components/Form/Form'
import FormCheckbox from '@components/Form/FormCheckbox'
import TextInput from '@components/Form/TextInput'
import FormSchoolSelector from '@components/Form/FormSchoolSelector'
import SEOMetaInfo from '@components/SEOMetaInfo'
import useNewForms from '@utils/useNewForms'
import AddProfessorForm from '@components/Storybook/AddProfessorForm/AddProfessorForm'
import { StyledSelect } from '@components/Form/FormSelect'
import DepartmentSelection from './DepartmentSelection'
import addProfessorPageMutation from './AddProfessor.utils'
import SubmitButtonArea from './SubmitButtonArea'

const StyledAddProfessorPage = styled(PageWrapper)`
  text-align: left;
  ${breakpointLargePlusAndBelow(css`
    padding: 0 24px;
  `)}
  ${breakpointTinyAndBelow(css`
    padding: 0;
  `)}
`
const SchoolSearchWrapper = styled.div`
  max-width: 441px;
`
const AddProfHeading = styled.div`
  font-size: 42px;
  line-height: 60px;
  ${poppinsBoldest}
`

const AddProfSubHeading = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
`

export const AddProfForm = styled(Form)`
  margin-top: 35px;
  ${props => props.isNewForms && `display: none;`}
  ${({ theme }) => getPageContentMaxWidth(theme)};

  ${StyledSelect} {
    max-width: 441px;
  }
`

const StyledTextInput = styled(TextInput)`
  max-width: 441px;
`

const StyledCheckbox = styled(FormCheckbox)`
  font-size: 16px;
  font-weight: 500;
  margin-right: 4px;
`

const CheckboxWrapper = styled.div`
  margin-top: 40px;
`

const AddProfessorPage = () => {
  const history = useHistory()
  const isNewForms = useNewForms()

  const ref = useRef({})

  const [headerData, setHeaderData] = useState({
    schoolId: '',
    profLastName: ''
  })

  const subHeader =
    'Please use the search bar above to make sure that the professor does not already exist at this school.'

  return (
    <StyledAddProfessorPage>
      <SEOMetaInfo title="Add a professor | Rate My Professors" />
      <AddProfHeading>Add a Professor</AddProfHeading>
      <AddProfSubHeading>{subHeader}</AddProfSubHeading>

      <AddProfessorForm isNewForms={isNewForms} />

      <AddProfForm
        isNewForms={isNewForms}
        getVariables={model => {
          setHeaderData({
            schoolId: model.school,
            profLastName: model.lastName
          })
          return {
            firstName: model.firstName,
            middleName: model.middleName,
            lastName: model.lastName,
            department: model.department,
            directoryUrl: model.directoryUrl,
            schoolName: model.schoolName,
            schoolID: model.school,
            recaptcha: model.recaptcha
          }
        }}
        mutation={addProfessorPageMutation}
        handleResult={ref}
        onSuccess={() => {
          history.push({
            pathname: ROUTE.ADD_PROFESSOR_SUCCESS,
            search: `?sid=${headerData.schoolId}`,
            state: {
              profLastName: headerData.profLastName,
              profLegacyId: ref.current.addTeacher.teacher.legacyId
            }
          })
        }}
      >
        <SchoolSearchWrapper>
          <FormSchoolSelector
            name="school"
            label="Name of School"
            labelStyle={LABEL_STYLE.TOP}
            inModal
            addNewProf
          />
        </SchoolSearchWrapper>
        <StyledTextInput
          name="firstName"
          label="Professor's First Name"
          labelStyle={LABEL_STYLE.TOP}
        />
        <StyledTextInput
          name="middleName"
          label="Professor's Middle Name"
          labelStyle={LABEL_STYLE.TOP}
          optional
        />
        <StyledTextInput
          name="lastName"
          label="Professor's Last Name"
          labelStyle={LABEL_STYLE.TOP}
        />
        <DepartmentSelection isSearchable />
        <StyledTextInput
          name="directoryUrl"
          label="Directory Listing of Professor"
          labelStyle={LABEL_STYLE.TOP}
        />
        <CheckboxWrapper>
          <StyledCheckbox
            label="I agree to the Terms of Use and Privacy Policy"
            name="termsPrivacy"
          />
        </CheckboxWrapper>
        <SubmitButtonArea />
      </AddProfForm>
      <AdRail />
    </StyledAddProfessorPage>
  )
}

const withRouterAddProfessorPage = withRouter(AddProfessorPage)

export default withRouterAddProfessorPage
