import React, { useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import {
  breakpointSmediumAndBelow,
  breakpointSmallPlusAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import SchoolTypes from '@types/School.types'
import CaretDown from '@assets/images/caret-down-grey.svg'
import CaretUp from '@assets/images/caret-up-grey.svg'
import CategoryGrade from './CategoryGrade'
import schoolSummaryModel from './SchoolSummary.model'

const SchoolSummaryWrapper = styled.div`
  flex-flow: column;
  margin-bottom: 24px;
  padding-right: 24px;
  ${breakpointSmallPlusAndBelow(css`
    padding: 0;
  `)}
`

const SchoolSummaryContainer = styled.div`
  column-gap: 110px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 264px;
  justify-content: space-between;
  width: 628px;
  ${breakpointSmediumAndBelow(css`
    margin-top: 32px;
  `)}
  ${breakpointSmallPlusAndBelow(css`
    display: ${props => (props.showAll ? 'flex' : 'none')};
    height: unset;
  `)}
`

const SchoolSummaryMobileContainer = styled.div`
  display: none;
  flex-direction: column;
  margin-top: 24px;
  ${breakpointSmallPlusAndBelow(css`
    display: ${props => (props.showAll ? 'none' : 'flex')};
  `)}
  hr {
    border: ${({ theme }) => `1px solid ${theme.color.gray4}`};
    width: 100%;
  }
`

const MobileTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
`

const ShowAllBtn = styled.button`
  background: transparent;
  border: 0;
  display: none;
  font-size: 14px;
  ${breakpointSmallPlusAndBelow(css`
    display: initial;
  `)}
`

const BtnText = styled.span`
  color: ${({ theme }) => theme.color.gray8};
  font-weight: bold;
  margin-right: 5px;
  text-decoration: underline;
`

const SchoolSummary = ({ school }) => {
  const { summary } = school
  const [showAll, setShowAll] = useState(false)

  const generateCategories = () => {
    const categories = Object.entries(summary).map(([name, score]) => {
      return (
        <CategoryGrade
          key={`${name}-${score}`}
          name={schoolSummaryModel[name].name}
          score={score}
          icon={schoolSummaryModel[name].icon}
        />
      )
    })
    const sortedCategories = categories.sort((a, b) => {
      if (a.props.score > b.props.score) {
        return -1
      }
      if (a.props.score < b.props.score) {
        return 1
      }
      return 0
    })
    return sortedCategories
  }

  const categoriesList = generateCategories()

  const highestRated = categoriesList[0]
  const lowestRated = categoriesList[9]

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  return (
    <SchoolSummaryWrapper>
      <SchoolSummaryContainer showAll={showAll}>{categoriesList}</SchoolSummaryContainer>
      <SchoolSummaryMobileContainer showAll={showAll}>
        <MobileTitle>Highest Rated</MobileTitle>
        <hr />
        <div>{highestRated}</div>
        <MobileTitle>Lowest Rated</MobileTitle>
        <hr />
        <div>{lowestRated}</div>
      </SchoolSummaryMobileContainer>
      <ShowAllBtn type="button" onClick={handleShowAll}>
        {showAll ? (
          <div>
            <BtnText>Show less</BtnText>
            <img src={CaretUp} alt="Caret Up" />
          </div>
        ) : (
          <div>
            <BtnText>Show all</BtnText>
            <img src={CaretDown} alt="Caret Down" />
          </div>
        )}
      </ShowAllBtn>
    </SchoolSummaryWrapper>
  )
}

export default createFragmentContainer(SchoolSummary, {
  school: graphql`
    fragment SchoolSummary_school on School {
      summary {
        schoolReputation
        schoolSatisfaction
        internetSpeed
        campusCondition
        schoolSafety
        careerOpportunities
        socialActivities
        foodQuality
        clubAndEventActivities
        campusLocation
      }
    }
  `
})

SchoolSummary.propTypes = {
  school: SchoolTypes
}
