import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import isSSR from '@src/utils/isSSR'
import ROUTE from '@enums/ROUTE'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import LightHeader from '@pages/Homepage/HomepageHeader'
import YellowBlob from '@assets/images/bkgd-blob-yellow.svg'
import useCurrentRoute from '@hooks/useCurrentRoute'
import { matchPath } from 'react-router-dom'
import DarkHeader from '@components/Header/Header'
import toast, { TOAST_EVENTS } from '@utils/toast'
import useUserInfo from '@hooks/useUserInfo'
import EmailConfirmationReminderToast from '@components/Toast/EmailConfirmationReminderToast'
import useIsWebViewPage from '@hooks/useIsWebViewPage'
import routeConfigs from '@src/config/routeConfig'
import ModalRoot from './Modals/ModalRoot'
import AdLeaderboard from './Ads/AdLeaderboard'

const shouldUseLightHeader = currentRoute => {
  return (currentRoute && currentRoute.usesLightHeader) || false
}

const lightHeaderContainerStyles = css`
  background-image: url("${YellowBlob}");
  background-position-x: ${props => props.theme.bkgrdGraphicOffsets.yellowBlob.x};
  background-position-y: ${props => props.theme.bkgrdGraphicOffsets.yellowBlob.y};
  background-repeat: no-repeat;
  position: static;
  ${breakpointSmediumAndBelow(
    css`
      background-image: none;
      margin: 0 auto;
    `
  )}

`

const StyledHeaderContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.header};
  ${props => props.hasLightHeader && lightHeaderContainerStyles};
`

const routeAllowsEmailConfirmationPrompt = currentRoute => {
  return (currentRoute && currentRoute.allowsEmailConfirmationPrompt) || false
}

export const HeaderContainer = () => {
  const route = useCurrentRoute()
  const useLightHeader = shouldUseLightHeader(route)

  const userInfo = useUserInfo()
  const isLoggedIn = !!userInfo?.Email
  const isUnconfirmedUser = isLoggedIn && userInfo.emailConfirmed === false

  /**
   * Pages must explicitly have this set in their config in order for default leaderboard to show
   */
  const showLeaderboard = () => {
    const currentRoute = routeConfigs.find(rc => matchPath(route.path, rc))
    return (currentRoute && currentRoute.showLeaderboard) || false
  }

  useEffect(() => {
    if (
      routeAllowsEmailConfirmationPrompt(route) &&
      isUnconfirmedUser &&
      route.path !== ROUTE.HOME
    ) {
      toast(EmailConfirmationReminderToast, TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER)
    }
  }, [route, isUnconfirmedUser])

  if (useIsWebViewPage()) return null

  return (
    <React.Fragment>
      <StyledHeaderContainer hasLightHeader={useLightHeader} data-testid="StyledHeaderContainer">
        {useLightHeader ? <LightHeader /> : <DarkHeader />}
        {!isSSR() && <ModalRoot />}
        {showLeaderboard() && <AdLeaderboard code="ad_lb_home" center />}
      </StyledHeaderContainer>
    </React.Fragment>
  )
}

export default HeaderContainer
