import React from 'react'
import styled from 'styled-components'
import theme from '@components/StyledComponents/theme'

export const StyledAdLabel = styled.div`
  background-color: ${theme.color.gray};
  font-size: 10px;
  position: absolute;
`

const AdLabel = () => {
  return <StyledAdLabel>Advertisement</StyledAdLabel>
}

export default AdLabel
