import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import LABEL_STYLE, { LabelStyleTypes } from '@enums/LABEL_STYLE'
import FormSelect, { StyledSelect } from '@components/Form/FormSelect'
import FormContext from '@contexts/FormContext'
import { breakpointSmallPlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

const StyledFormSelect = styled(FormSelect)`
  ${StyledSelect} {
    width: 100%;
    ${breakpointSmallPlusAndBelow(css`
      width: 100%;
    `)}
  }
  div {
    z-index: ${props => props.theme.zIndex.dropdown};
  }
`

export function DepartmentSelection({
  disabled,
  name = 'department',
  label = 'Department',
  labelStyle = LABEL_STYLE.TOP,
  ...props
}) {
  const [options, setOptions] = useState([])
  const { model } = useContext(FormContext)
  useEffect(() => {
    if (model?.school?.departments) {
      const { departments } = model.school
      const formattedDepartments = departments.map(dep => {
        return {
          value: dep.name,
          label: dep.name
        }
      })
      setOptions(formattedDepartments)
    }
  }, [model?.school ?? []])

  return (
    <StyledFormSelect
      disabled={disabled}
      name={name}
      options={options}
      label={label}
      labelStyle={labelStyle}
      fullWidth
      {...props}
    />
  )
}

DepartmentSelection.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  labelStyle: LabelStyleTypes
}

export default DepartmentSelection
