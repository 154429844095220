/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import styled from 'styled-components'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SearchContext from '@contexts/SearchContext'
import SEARCH_CATEGORY, { SearchCategoryType } from '@enums/SEARCH_CATEGORY'
import ROUTE from '@enums/ROUTE'
import { getUserSchool } from '@utils/getAndSetUserSchool'
import { SEARCH_DEBOUNCE_TIME } from '@src/CONSTANTS'
import useUserInfo from '@hooks/useUserInfo'
import SearchTypeahead, { MIN_SEARCH_LENGTH } from './SearchTypeahead'
import { getItemURL } from './MenuItem'
import DebouncedInput from './DebouncedInput'

export const teacherConfig = { type: SEARCH_ITEM_TYPES.TEACHERS, displayName: 'Teachers' }
export const schoolConfig = { type: SEARCH_ITEM_TYPES.SCHOOLS, displayName: 'Schools' }

export function getConfigForCategory(category) {
  let config = [teacherConfig, schoolConfig]
  if (category === SEARCH_CATEGORY.SCHOOLS) {
    config = config.filter(c => c.type === SEARCH_ITEM_TYPES.SCHOOLS)
  } else if (category === SEARCH_CATEGORY.TEACHERS) {
    config = config.filter(c => c.type === SEARCH_ITEM_TYPES.TEACHERS)
  }
  return config
}

const navigateUp = (selectedIndex, setSelectedIndex, itemsLength) => {
  if (selectedIndex === null) {
    setSelectedIndex(itemsLength - 1)
    return
  }

  if (selectedIndex === 0) {
    setSelectedIndex(null)
    return
  }

  setSelectedIndex(selectedIndex - 1)
}

const navigateDown = (selectedIndex, setSelectedIndex, itemsLength) => {
  if (selectedIndex === null || selectedIndex === itemsLength - 1) {
    setSelectedIndex(0)
    return
  }

  setSelectedIndex(selectedIndex + 1)
}

const getIsTeacherSearch = searchCategory => {
  return searchCategory === SEARCH_ITEM_TYPES.TEACHERS
}

const getSearchRoute = category => {
  const isTeacherSearch = getIsTeacherSearch(category)

  return isTeacherSearch ? ROUTE.TEACHER_SEARCH : ROUTE.SCHOOL_SEARCH
}

function redirectToSearch(config) {
  if (!config?.inputValue || config?.inputValue === '') return

  const { inputValue, history, user, category } = config

  const schoolPathParam = getUserSchool(user)?.legacyId ?? ''

  const paramToReplace = schoolPathParam ? ':sid?' : '/:sid?'

  history.push({
    pathname: getSearchRoute(category).replace(paramToReplace, schoolPathParam),
    search: `?q=${inputValue}`
  })
}

export const defaultOnItemSelected = (history, event, itemType, item, setIsOpen) => {
  event.preventDefault()

  const itemURL = getItemURL(itemType, item)
  const [pathname, search] = itemURL.split('?')

  if (itemType === SEARCH_ITEM_TYPES.TEACHERS) {
    history.push({ pathname, search: `?${search}` })
  } else {
    window.location.assign(`${itemURL}`)
  }
  setIsOpen(false)
}

const handleEnterKey = config => {
  const {
    allowRedirect,
    history,
    selectedItem,
    itemType,
    event,
    onItemSelected,
    setIsOpen,
    selectedIndex,
    user,
    category
  } = config
  event.preventDefault()

  // Remove whitespace
  const inputValue = event.target.value.trim()

  // If the input value is empty or contains only spaces, return without performing any action
  if (!inputValue) {
    return
  }

  if (!selectedItem || selectedIndex === null) {
    if (allowRedirect)
      redirectToSearch({
        inputValue: event.target.value,
        itemType,
        history,
        user,
        category
      })
    return
  }

  onItemSelected(history, event, itemType, selectedItem, setIsOpen)
}

export const SearchInputContainer = styled.div`
  position: relative;
`

// Export this to allow it to be styled by others
export const DebouncedSearchInput = styled(DebouncedInput)``

export function SearchComponent({
  history,
  placeholder,
  className,
  category = SEARCH_CATEGORY.ALL,
  disabled = false,
  allowRedirect = true,
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},
  setIconCategory = () => {},
  onItemSelected = defaultOnItemSelected,
  query,
  searchQueryConfig = null,
  inputRef,
  inModal,
  count,
  inputValue,
  setInputValue,
  hideLink,
  setError
}) {
  /**
   * This state variable controls what is actually sent via search query vs. what is typed in
   */
  const [debouncedQueryValue, setDebouncedQueryValue] = useState('')

  const configForCategory = getConfigForCategory(category)
  const [searchCategory, setSearchCategory] = useState(category)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [itemsLength, setItemsLength] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [itemType, setItemType] = useState(configForCategory[0].type)
  const [isOpen, setIsOpen] = useState(false)
  const [searchResults, setSearchResults] = useState(null)
  const [disableEnterKey, setDisableEnterKey] = useState(false)

  const user = useUserInfo()

  useEffect(() => {
    if (searchCategory !== category || !inputValue?.length) {
      setSearchCategory(category)
    }

    if (!inputValue?.length) {
      setIconCategory(category)
    }

    setSelectedItem(null)
    setSearchResults(null)
    setItemsLength(0)
  }, [category])

  const onEnterConfig = {
    allowRedirect,
    history,
    selectedItem,
    itemType,
    onItemSelected,
    setIsOpen,
    selectedIndex
  }

  const inputOnChange = v => {
    /**
     * This transfers what we have typed in (inputValue) to what we want to query for after debouncing
     */
    setDebouncedQueryValue(v)
  }

  const inputOnKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        if (!disableEnterKey) {
          handleEnterKey({ ...onEnterConfig, event, user, category })
        }
        break
      case 'ArrowUp':
        navigateUp(selectedIndex, setSelectedIndex, itemsLength)
        break
      case 'ArrowDown':
        navigateDown(selectedIndex, setSelectedIndex, itemsLength)
        break
      default:
        onKeyDown(event.target.value)
        break
    }
  }

  const getShowTypeahead = text => {
    /**
     * This prevents strings of empty spaces triggering search queries
     * and ensures the query value is greater than our configured minimum
     */
    return text.trim().length >= MIN_SEARCH_LENGTH
  }

  const showTypeahead = getShowTypeahead(debouncedQueryValue)

  return (
    <SearchInputContainer inModal={inModal} className={className}>
      <SearchContext.Provider value={{ onItemSelected, setIsOpen }}>
        <DebouncedSearchInput
          disabled={disabled}
          type="text"
          time={SEARCH_DEBOUNCE_TIME}
          minLength={MIN_SEARCH_LENGTH}
          aria-label="search"
          placeholder={placeholder}
          onChange={inputOnChange}
          onKeyDown={e => inputOnKeyDown(e)}
          onFocus={e => {
            onFocus(e)
            setIsOpen(true)
          }}
          onBlur={e => {
            onBlur(e)
            setIsOpen(false)
          }}
          value={inputValue}
          setValue={setInputValue}
          inputRef={inputRef}
          setError={setError}
          setDisableEnterKey={setDisableEnterKey}
        />
        {showTypeahead && (
          <SearchTypeahead
            {...{
              query,
              config: configForCategory,
              queryValue: debouncedQueryValue,
              setSelectedIndex,
              selectedIndex,
              setItemsLength,
              selectedItem,
              setSelectedItem,
              itemType,
              setItemType,
              searchResults,
              setSearchResults,
              isOpen,
              itemsLength,
              searchQueryConfig: searchQueryConfig ? { ...searchQueryConfig, category } : null,
              count,
              hideLink
            }}
          />
        )}
      </SearchContext.Provider>
    </SearchInputContainer>
  )
}

SearchComponent.propTypes = {
  history: ReactRouterPropTypes.history,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  allowRedirect: PropTypes.bool,
  disabled: PropTypes.bool,
  onItemSelected: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  category: SearchCategoryType,
  query: PropTypes.func,
  searchQueryConfig: PropTypes.shape({
    schoolId: PropTypes.string
  }),
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.node
    }),
    PropTypes.shape({
      current: null
    })
  ]),
  setIconCategory: PropTypes.func,
  inModal: PropTypes.bool,
  count: PropTypes.number,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  hideLink: PropTypes.bool,
  setError: PropTypes.func
}

export default withRouter(SearchComponent)
