import React, { useState, useEffect } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  getPageContainerMaxWidth,
  breakpointSmediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import HeaderDescription from '@components/StickyHeader/HeaderDescription'
import USERTYPES from '@enums/USERTYPES'
import UserTypes from '@types/User.types'
import useCengage from '@src/utils/useCengage'
import HeaderRateButton, {
  StyledRateTeacherButton,
  StyledRateSchoolButton
} from './HeaderRateButton'

const StyledStickyHeaderContainer = styled.header`
  background: white;
  box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.25);
  height: auto;
  padding: 24px;
  position: sticky;
  top: ${props => (props.cengage ? '99px' : '64px')};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.stickyHeader};

  ${breakpointSmediumAndBelow(css`
    padding: 18px 24px;
    position: static;
    top: ${props => (props.cengage ? '110px' : '54px')};
    ${props =>
      props.scrolled &&
      css`
        position: sticky;
        transform: translate(0, -200px);
      `}
    ${props =>
      props.showHeader &&
      css`
        transform: translate(0, 0);
        transition: all 0.3s ease;
      `}
  `)}
`
const StyledStickyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  transition: all 0.3s;

  ${({ theme }) => getPageContainerMaxWidth(theme)}

  ${breakpointSmediumAndBelow(css`
    flex-direction: ${props => (props.scrolled ? 'row' : 'column')};
    ${StyledRateTeacherButton} {
      padding: ${props => (props.scrolled ? '6px 16px' : '14px 34px;')};
    }
    ${StyledRateSchoolButton} {
      padding: ${props => (props.scrolled ? '6px 16px' : '14px 34px;')};
    }
  `)}
`

export const StyledSecondaryContainerOne = styled.div`
  display: flex;
  flex-direction: column;
`

export function StickyHeader({ teacher, userInfo, large, school }) {
  const isUserProf = userInfo?.Type === USERTYPES.TEACHER
  const isCengage = useCengage()
  const [scrolled, setScrolled] = useState(false)
  const [showHeader, setShowHeader] = useState(false)

  useEffect(() => {
    const headerHeights = 238

    const onScroll = () => {
      setScrolled(window.pageYOffset > headerHeights)
      setShowHeader(window.pageYOffset > headerHeights * 1.25)
    }
    if (window) window.addEventListener('scroll', onScroll)
    return () => {
      if (window) window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <StyledStickyHeaderContainer showHeader={showHeader} scrolled={scrolled} cengage={isCengage}>
      <StyledStickyHeader scrolled={scrolled}>
        <StyledSecondaryContainerOne>
          {(teacher || school) && (
            <HeaderDescription teacher={teacher} large={large} school={school} />
          )}
          {!isUserProf && large && (
            <HeaderRateButton teacher={teacher} school={school} scrolled={scrolled} />
          )}
        </StyledSecondaryContainerOne>
      </StyledStickyHeader>
    </StyledStickyHeaderContainer>
  )
}

export default createFragmentContainer(StickyHeader, {
  teacher: graphql`
    fragment StickyHeader_teacher on Teacher {
      ...HeaderDescription_teacher
      ...HeaderRateButton_teacher
    }
  `,
  school: graphql`
    fragment StickyHeader_school on School {
      ...HeaderDescription_school
      ...HeaderRateButton_school
    }
  `
})
StickyHeader.propTypes = {
  teacher: TeacherTypes,
  userInfo: UserTypes,
  large: PropTypes.bool,
  school: SchoolTypes
}
