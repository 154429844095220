/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HeaderDescription_teacher$ref = any;
type HeaderRateButton_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StickyHeader_teacher$ref: FragmentReference;
declare export opaque type StickyHeader_teacher$fragmentType: StickyHeader_teacher$ref;
export type StickyHeader_teacher = {|
  +$fragmentRefs: HeaderDescription_teacher$ref & HeaderRateButton_teacher$ref,
  +$refType: StickyHeader_teacher$ref,
|};
export type StickyHeader_teacher$data = StickyHeader_teacher;
export type StickyHeader_teacher$key = {
  +$data?: StickyHeader_teacher$data,
  +$fragmentRefs: StickyHeader_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "StickyHeader_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "HeaderDescription_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "HeaderRateButton_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8375902e4170093feb692b3e3934c02d';
module.exports = node;
