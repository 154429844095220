import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { applyBreakpointMin } from '@components/StyledComponents/theme/helpers/breakpoints'

export const AdWrapper = styled.div`
  min-height: 50px;
  padding: 7px 0 24px;
  ${applyBreakpointMin(
    'medium',
    css`
      min-height: 90px;
    `
  )}
`

const BrowsiAd = ({ id }) => {
  return <AdWrapper id={id} className="browsi_rmp_web" />
}

BrowsiAd.propTypes = {
  id: PropTypes.string
}

export default BrowsiAd
